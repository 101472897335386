//@ts-ignore
import dynamic from 'next/dynamic';
const AnnouncementBarTastic = dynamic(() => import('./bar/announcment'));
const CategorySliderTastic = dynamic(() => import('./category-slider'));
const ContentSliderTastic = dynamic(() => import('./content-slider'));
const HeroTastic = dynamic(() => import('./content/hero'));
const Tile = dynamic(() => import('./content/tile'));
const TilesGroupTastic = dynamic(() => import('./content/tiles-group'));
const Footer = dynamic(() => import('./footer'));
const Header = dynamic(() => import('./header'));
const Missing = dynamic(() => import('./missing'));
const NotFoundTastic = dynamic(() => import('./not-found'));
const ProductSlider = dynamic(() => import('./products/slider'));
const SpacerTastic = dynamic(() => import('./spacer'));

//B2B
const B2bTestComponent = dynamic(() => import('./b2b/Test'));
const B2BLogin = dynamic(() => import('./b2b/login'));
const B2BPDP = dynamic(() => import('./b2b/pdp'));
const B2BHome = dynamic(() => import('./b2b/home/Home'));
const B2BPLP = dynamic(() => import('./b2b/plp/Plp'));
const B2BShoppingCart = dynamic(() => import('./b2b/shoppingCart/ShoppingCart'));
const B2BOrders = dynamic(() => import('./b2b/orders/Orders'));
const B2BCheckout = dynamic(() => import('./b2b/checkout/Checkout'));
const B2BOrderDetails = dynamic(() => import('./b2b/orderDetails/OrderDetails'));
const B2BCheckoutFinished = dynamic(() => import('./b2b/checkout/Finished'));
const B2BOrderCancelled = dynamic(() => import('./b2b/orderCancelled/OrderCancelled'));
const B2BShoppingList = dynamic(() => import('./b2b/shoppingList/ShoppingList'));
const B2BNotFound = dynamic(() => import('./b2b/404/NotFound404'));
const B2BAccountConfiguration = dynamic(() => import('./b2b/accountConfiguration/AccountConfiguration'));
const B2BShoppingListDetails = dynamic(() => import('./b2b/shoppingListDetails/ShoppingListDetails'));
const B2BSuccessfulReturn = dynamic(() => import('./b2b/successfulReturn/SuccessfulReturn'));
const B2BRotoplasCredit = dynamic(() => import('./b2b/rotoplasCredit/RotoplasCredit'));
const B2BTransactionHistory = dynamic(() => import('./b2b/transactionHistory/TransactionHistory'));
const B2BOrderReturn = dynamic(() => import('./b2b/orderReturn/OrderReturn'));
const B2BAnnouncements = dynamic(() => import('./b2b/announcements/Announcements'));
const B2BCancelations = dynamic(() => import('./b2b/cancelaciones/Cancelations'));
const B2BDevolutions = dynamic(() => import('./b2b/devoluciones/Devolutions'));
const B2BUsersManagement = dynamic(() => import('./b2b/usersManagement/UsersManagement'));
const B2BTermsAndConditions = dynamic(() => import('./b2b/termsAndConditions/TermsAndConditions'));
const B2BPrivacyNotice = dynamic(() => import('./b2b/privacyNotice/PrivacyNotice'));
const B2BHolaMundoComponent = dynamic(() => import('./b2b/1aTest/HolaMundo'));
const B2BEcommerceB2C = dynamic(() => import('./b2b/ecommerceB2C/EcommerceB2C'));
const B2BEcommerceB2CDetails = dynamic(() => import('./b2b/ecommerceB2CDetails/EcommerceB2CDetails'));
const B2BEcommerceB2CIncidence = dynamic(() => import('./b2b/ecommerceB2CIncidence/EcommerceB2CIncidente'));
const B2BOrdersOfflines = dynamic(() => import('./b2b/orders-offlines/OrdersOffline'))
const B2BOrderOfflinesDetails = dynamic(() => import('./b2b/orderOfflineDetails/OrdersOfflineDetails'))





const Markdown = dynamic(() => import('./markdown'));
const AccountDetails = dynamic(() => import('./account/details'));
const AccountLogin = dynamic(() => import('./account/login'));
const AccountOrdersHistory = dynamic(() => import('./account/orders'));
const AccountRegister = dynamic(() => import('./account/register'));
const ResetPassword = dynamic(() => import('./account/reset-password'));
const Checkout = dynamic(() => import('./checkout'));
const ThankYou = dynamic(() => import('./thank-you'));
const ProductDetails = dynamic(() => import('./products/details'));
const ProductList = dynamic(() => import('./products/product-list'));
const ProductListAlgolia = dynamic(() => import('./products/product-list-algolia'));
const SimilarProducts = dynamic(() => import('./products/similar-products'));
const OtherProducts = dynamic(() => import('./products/other-products'));
const Showcase = dynamic(() => import('./showcase'));
const Cart = dynamic(() => import('./cart'));

export const tastics = {
  'commercetools/ui/checkout': Checkout,
  'commercetools/ui/thank-you': ThankYou,
  'commercetools/ui/footer': Footer,
  'commercetools/ui/header': Header,
  'commercetools/ui/content/tile': Tile,
  'commercetools/ui/content/hero': HeroTastic,
  'commercetools/ui/content/spacer': SpacerTastic,
  'commercetools/ui/content/showcase': Showcase,
  'commercetools/ui/content/markdown': Markdown,
  'commercetools/ui/cart': Cart,
  'commercetools/ui/products/details': ProductDetails,
  'commercetools/ui/products/product-list': ProductList,
  'commercetools/ui/products/product-list-algolia': ProductListAlgolia,
  'commercetools/ui/products/slider': ProductSlider,
  'commercetools/ui/products/similar-products': SimilarProducts,
  'commercetools/ui/products/other-products': OtherProducts,
  'commercetools/ui/account/details': AccountDetails,
  'commercetools/ui/account/login': AccountLogin,
  'commercetools/ui/account/register': AccountRegister,
  'commercetools/ui/account/orders': AccountOrdersHistory,
  'commercetools/ui/account/reset-password': ResetPassword,
  'commercetools/ui/category-slider': CategorySliderTastic,
  'commercetools/ui/content/announcement-bar': AnnouncementBarTastic,
  'commercetools/ui/content/content-slider': ContentSliderTastic,
  'commercetools/ui/content/tiles-group': TilesGroupTastic,
  'commercetools/ui/not-found': NotFoundTastic,

  //B2B
  'sso/rotoplas/b2b/holaMundoTest':B2BHolaMundoComponent,
  'sso/rotoplas/b2b/b2bTest': B2bTestComponent,
  'sso/rotoplas/b2b/login': B2BLogin,
  'sso/rotoplas/b2b/pdp': B2BPDP,
  'sso/rotoplas/b2b/home': B2BHome,
  'sso/rotoplas/b2b/plp': B2BPLP,
  'ehe/rotoplas/b2b/shoppingCart': B2BShoppingCart,
  'ehe/rotoplas/b2b/orders': B2BOrders,
  'sso/rotoplas/b2b/checkout': B2BCheckout,
  'myc/rotoplas/b2b/orderDetails': B2BOrderDetails,
  'sso/rotoplas/b2b/checkout/finished': B2BCheckoutFinished,
  'myc/rotoplas/b2b/orderCancelled': B2BOrderCancelled,
  'myc/rotoplas/b2b/shoppingList': B2BShoppingList,
  'sso/rotoplas/b2b/notFound': B2BNotFound,
  'sso/rotoplas/b2b/accountConfiguration': B2BAccountConfiguration,
  'myc/rotoplas/b2b/list': B2BShoppingListDetails,
  'ehe/rotoplas/b2b/successfulReturn': B2BSuccessfulReturn,
  'sso/rotoplas/b2b/rotoplasCredit': B2BRotoplasCredit,
  'sso/rotoplas/b2b/transactionHistory': B2BTransactionHistory,
  'sso/rotoplas/b2b/orderReturn': B2BOrderReturn,
  'myc/rotoplas/b2b/announcements': B2BAnnouncements,
  'myc/rotoplas/b2b/usersManagement': B2BUsersManagement,
  'ehe/rotoplas/b2b/termsAndConditions': B2BTermsAndConditions,
  'sso/rotoplas/b2b/privacyNotice': B2BPrivacyNotice,
  'sso/rotoplas/b2b/devolutions': B2BDevolutions,
  'sso/rotoplas/b2b/cancelations': B2BCancelations,
  'sso/rotoplas/b2b/ecommerceb2c':B2BEcommerceB2C,
  "sso/rotoplas/b2b/ecommerceb2c-details":B2BEcommerceB2CDetails,
  "sso/rotoplas/b2b/ecommerce-b2c-incidence":B2BEcommerceB2CIncidence,
  "josue/rotoplas/b2b/ordersOffline": B2BOrdersOfflines,
  "josue/rotoplas/b2b/ordersOfflineDetails": B2BOrderOfflinesDetails,

  default: Missing,
};
